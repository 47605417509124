@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "variables";

@mixin capture-text {
  font-size: 14px;
  line-height: 22px;
}

@mixin hollow-button($color) {
  background: white;
  color: $color;
  border-color: $color;
}

@mixin light-button-variant($color) {
  background: $white;

  @include hover {
    color: $color;
  }

  &:not(:disabled):not(.disabled):active {
    color: adjust_color($color, $lightness: -5%);
    background: $white;
  }
}
