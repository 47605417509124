@import "../variables";

.text-block {
  position: relative;

  p {
    margin-bottom: 20px;
  }

  img {
    margin-bottom: 30px;

    &.float-left {
      margin-right: 30px;
    }

    &.float-right {
      margin-left: 30px;
    }

    &.half {
      width: 50%;
    }
  }

  &__lead {
    font-family: $font-istok-web;
    font-size: $h4-font-size;
    line-height: 26px;
  }
}
