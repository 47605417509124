.partner-card {
  display: inline-block;
  margin-right: 4px;
  text-align: center;
  width: 178px;
  height: 82px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
