@import "variables";
@import "mixins";

html {
  height: 100%;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1px;

  @include media-breakpoint-down('sm') {
    font-size: 14px;
  }
}

body {
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5 {
  line-height: calc(1em + 8px);
}

h1 {
  letter-spacing: -0.2px;
}

h2 {
  letter-spacing: -0.1px;
}

h3 {
  letter-spacing: 0;
}

h4 {
  letter-spacing: 0;
}

h5 {
  font-weight: bold;
  letter-spacing: 0.02em;
}

.ws-pre-line {
  white-space: pre-line;
}

.container, .container-fluid {
  min-width: 320px;
}

.capture, .help-block, .valid-feedback, .invalid-feedback {
  @extend .small
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value, lighten($value, 7.5%), lighten($value, 7.5%), darken($value, 6%), darken($value, 6%));

    color: $yiq-text-light;

    @include hover {
      color: $yiq-text-light;
    }

    &:not(:disabled):not(.disabled):active {
      color: adjust_color($yiq-text-light, $alpha: -0.2);
    }

    //&:disabled {
    //  background: #F4F7FB;
    //  color: $gray-600;
    //}
  }

  .btn-outline-#{$color} {
    border-width: $border-width;
    font-weight: $font-weight-bold;
  }

  .tooltip.tooltip-#{$color} {
    &.bs-tooltip-top    .arrow::before  { border-top-color:    $value }
    &.bs-tooltip-right  .arrow::before  { border-right-color:  $value }
    &.bs-tooltip-bottom .arrow::before  { border-bottom-color: $value }
    &.bs-tooltip-left   .arrow::before  { border-top-color:    $value }

    .tooltip-inner {
      background: $value;
    }
  }
}

.btn-light, .btn-outline-light {
  color: $dark;

  &:hover {
    color: $dark;
  }
}

.btn-lg {
  font-weight: 500;
}

.form-group {
  margin-bottom: 1.428rem;

  .form-control {
    &[readonly] {
      background: $light;
    }

    &:disabled, &.disabled {
      border-color: #F4F7FB;
      color: #DBE4EE;

      &::placeholder {
        color: #DBE4EE;
      }
    }

    &.is-invalid:not(select) {
      border-color: #F7B6B6;
      padding: $input-padding-y $input-padding-x;

      &.form-control-sm {
        padding: $input-padding-y-sm $input-padding-x-sm;
      }
    }
  }
}

@mixin form-control-append-border {
  &:not(:last-child) {
    border-right: none;
  }

  &:focus {
    ~ .input-group-append .input-group-text {
      border-color: $input-focus-border-color;
    }
  }

  &.is-invalid, &:invalid {
    ~ .input-group-append .input-group-text {
      border-color: #F7B6B6;
    }

  }

  ~ .input-group-append {
    .input-group-text {
      border-left: none;
    }

    &:last-of-type:not(:last-child) .input-group-text {
      @include border-right-radius($input-border-radius);
    }

    &:not(:last-of-type) .input-group-text {
      @include border-right-radius(0);
    }
  }
}

.input-group {
  > .form-control, > .custom-select { @include form-control-append-border }

  &.is-invalid {
    > .form-control, > .custom-select {
      &.is-invalid:focus {
        box-shadow: none;
      }
    }

    &:has(.form-control:focus), &:has(.custom-select:focus) {
      box-shadow: 0 0 0 $input-focus-width rgba($red, .25);
    }
  }
}

.custom-control {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.modal {
  .modal-header {
    padding: 2.142rem 1rem 2.142rem;

    .modal-title {
      text-align: center;
      width: 100%;

      &-link {
        float: right;
        margin-top: 0.8rem;
        font-size: 1rem;
        font-weight: $font-weight-base;
      }
    }

    .close {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: fixed;
      top: 3rem;
      right: 4rem;
      outline: none;
      background: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L21 21' stroke='%236D757E'/%3E%3Cpath d='M21 1L1 21' stroke='%236D757E'/%3E%3C/svg%3E%0A") center no-repeat;
    }
  }

  .modal-body {
    padding: 0 1rem 3.571rem
  }
}

.custom-range {
  &::-webkit-slider-thumb {
    background: white url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L2.12132 5.87868C0.949746 7.05025 0.949747 8.94975 2.12132 10.1213L7 15' stroke='%23242424'/%3E%3Cpath d='M18 1L22.8787 5.87868C24.0503 7.05025 24.0503 8.94975 22.8787 10.1213L18 15' stroke='%23242424'/%3E%3C/svg%3E%0A") center no-repeat
  }
}

@include table-row-variant('warning', #FFF9EE);

.table {
  th, td {
    border-right: $table-border-width solid $table-border-color;

    &:last-child {
      border-right: none;
    }
  }

  th {
    font-weight: normal;
  }

  thead th {
    border-bottom-width: $table-border-width;
  }
}

a.modal-title-link {
  text-decoration: none;
  color: $link-color;
}

.popover {
  background: #384049;
  border-radius: 5px;
  border: none;

  &-body {
    padding: 1rem 1.142rem;
    font-size: 1rem;
    color: white;
  }

  &.bs-popover-right {
    .arrow::after { border-right-color: #384049 }
  }

  &.bs-popover-top {
    .arrow::after { border-top-color: #384049 }
  }

  .arrow::before { border: none }
}

/******************
 * CUSTOM CLASSES *
 ******************/

.bg-green {
  background: $green;

  .btn-light {
    @include light-button-variant($green);
  }
}

.bg-blue {
  background: $blue;

  .btn-light {
    @include light-button-variant($blue);
  }
}

.page-item {
  margin: 0 .2rem;

  .page-link, .page-link:hover {
    @include border-radius($border-radius);
    text-decoration: none;
  }
}

@include media-breakpoint-down(md) {
  .w-md-100 {
    width: 100%;
  }
}

.separated-input-group {
  display: flex;

  input {
    flex-grow: 1;
    margin-right: 1rem;
  }

  button {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

h3.blue {
  color: $blue;
}

@include media-breakpoint-up(lg) {
  .modal-manual-container .modal-dialog {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-manual-container .modal-dialog {
    max-width: $modal-xl;
  }
}

input[type=number].no-appearance {
  -moz-appearance:textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none; /* <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}

/* RE-DESIGN STYLES */

.btn-arrow {
  outline: none !important;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  border-radius: 20px;
  background: transparentize($blue, 0.9) url(/assets/svg/redesign/arrow-right.svg) no-repeat center;

  &-grey {
    background-color: transparentize($dark, 0.92);
  }

  &-back {
    background-image: url("/assets/svg/redesign/arrow-left-black.svg");
  }
}

a:hover .btn-arrow, .btn-arrow:hover {
  background-color: adjust_color($blue, $alpha: -0.7);
}

a:active .btn-arrow, .btn-arrow:active {
  background-color: adjust_color($blue, $alpha: -0.5)
}

a:hover .btn-arrow.btn-arrow-grey, .btn-arrow.btn-arrow-grey:hover {
  background-color: adjust_color($dark, $alpha: -0.85);
}

a:active .btn-arrow.btn-arrow-grey, .btn-arrow.btn-arrow-grey:active {
  background-color: adjust_color($dark, $alpha: -0.7)
}

select.form-control {
  appearance: none;
  background-image: url(/assets/svg/redesign/arrow-down-blue.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 40px;

  option {
    font-weight: $font-weight-normal;
  }
}

.large {
  font-size: $h4-font-size;
}

.bg-gradient {
  background: $green-gradient;
}

.line-tabs {
  display: flex;
  column-gap: 30px;
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  border-bottom: 1px solid adjust_color($dark, $alpha: -0.9);

  .tab .nav-link {
    padding: 0 0 25px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    color: adjust_color($dark, $alpha: -0.6);

    &.active {
      border-bottom-color: $dark;
      color: $dark;
    }
  }

  &--small {
    font-size: .882rem;
    font-weight: $font-weight-normal;

    .tab .nav-link {
      padding: 9px 0;
      color: $dark;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .flex-basis#{$infix}-0 { flex-basis: 0 !important; }
  }
}

.app-badge {
  border-radius: 50%;
  background: $red;
  color: $white;
  font-size: 70%;
}

.triangle-card {
  position: relative;
  padding: 100px 0 60px;
  border-bottom: 1px solid $dark;
  flex-basis: 260px;
  min-width: 220px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 30px solid;
    background-clip: content-box;
  }

  &--circle {
    &::before {
      content: attr(data-circle);
      width: 60px;
      height: 60px;
      border-radius: 30px;
      line-height: 60px;
      text-align: center;
      color: $light;
      font-size: $h4-font-size;
      border: none;
    }
  }

  &--blue::before {
    border-color: $blue;
    background-color: $blue;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &--green::before {
    border-color: $green;
    background-color: $green;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}

ol.custom-list {
  padding: 0;
  counter-reset: custom-list;
  list-style: none;

  > li {
    padding-left: 60px;
    margin-bottom: 44px;
    position: relative;
    counter-increment: custom-list;

    &::before {
      content: counter(custom-list);
      position: absolute;
      right: calc(100% - 40px);
      top: -9px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
      color: $white;
      font-size: $h3-font-size;
      font-weight: $font-weight-bold;
      background: $primary;
    }

    &.custom-list-item-green::before {
      background: $success;
    }

    ul {
      border-left: 2px solid #187DC7;
      margin-left: -40px;
      margin-top: 20px;
      list-style: none;

      > li {
        padding-left: 26px;
        margin-bottom: 20px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background: $primary;
        }
      }
    }
  }
}

ul.check-list {
  margin: 0;
  padding-left: 0;
  font-weight: 500;
  font-size: $h4-font-size;

  & > li {
    position: relative;
    padding-left: 47px;
    margin-bottom: 30px;
    list-style: none;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background: url(/assets/svg/redesign/check-circle-green.svg) no-repeat center;
      background-size: contain;
    }
  }
}

.services-card {
  display: flex;
  flex-flow: column;
  justify-items: center;
  padding: 40px;

  @include media-breakpoint-down('md') {
    padding: 40px 30px;
  }

  & > h3, & > p {
    padding-right: 3vw;
    margin-bottom: 20px;
  }

  &--blue {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: $white;
    }

    background: $blue;
    color: $white;
  }

  &--white {
    background: $white;
  }

  &--sm-collapse {
    @include media-breakpoint-down('sm') {
      background: $white;
      padding: 20px 30px;
      flex-flow: row;
      align-items: center;

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        padding: 0;
        margin: 0;
      }

      & > p {
        display: none;
      }
    }
  }

  &--transparent {
    padding: 0;
    background: none;

    & > p {
      padding-right: 0;
    }
  }

  .services-card__arrow-btn {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: $white url(/assets/svg/redesign/arrow-right-60-green.svg) no-repeat 20px center;

    &--blue {
      background-color: $blue;
      background-image: url(/assets/svg/redesign/arrow-right-60-white.svg);
    }

    &--transparent {
      background-color: transparent;
    }
  }
}

.row .services-card {
  height: 100%;
}

.phone-whatsapp-icon {
  height: 1.2em;
  margin-top: -0.2em;
  margin-left: 0.2em;
}

.dropdown {
  &.show {
    .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: 0.155em;
    border: none;
    width: 10px;
    height: 6px;
    background: url(/assets/svg/redesign/arrow-down-white.svg) center no-repeat;
  }


  .dropdown-item {
    white-space: normal;
    border-left: 2px solid transparent;

    &.active {
      border-left-color: $green;
      background: $white;
    }

    &:hover {
      background-color: $hover-gray;
    }

    &:active {
      background-color: $dark;
      color: $white;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      white-space: normal;
      border-left: 3px solid transparent;
      color: $dark;
      text-decoration: none;
      font-weight: $font-weight-bold;

      &.active {
        border-left-color: $green;
      }

      &:hover {
        background-color: $hover-gray;
        color: $dark;
      }
    }
  }
}

.header-spacer {
  padding-top: $header-height + $header-top * 2;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.ngx-slider .ngx-slider-bubble {
  display: none !important;
}
