@import "../variables";

.blue-card {
  display: block;
  padding: 36px 50px 50px;
  margin-bottom: 20px;
  background: $light;
  border-radius: 4px;

  h3 {
    color: $blue;
    margin-bottom: 13px;
  }
}
