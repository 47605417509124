@import "../variables";

.image-card {
  display: block;
  padding: 45px 45px 20px;
  background: $light;
  border-radius: 3px;
  text-decoration: none;
  transition: 200ms;

  img {
    transition: 200ms;
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 25px;
  }

  .text {
    display: block;
    font-size: $h4-font-size;
    font-family: $font-istok-web;
    text-align: center;
  }

  &.h-100 {
    height: 100%;
  }
}

a.image-card {
  padding: 52px 45px 30px;

  .text {
    font-size: $h3-font-size;
  }

  &:hover {
    box-shadow: 0 4px 15px rgba(24, 125, 199, 0.25);
    font-weight: bold;

    img {
      width: 150px;
      height: 150px;
      margin-top: -15px;
      margin-bottom: 10px;
    }
  }
}
