/* You can add global styles to this file, and also import other style files */

@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";

@import "theme";


@import "styles/image-card";
@import "styles/text-block";
@import "styles/text-card";
@import "styles/blue-card";
@import "styles/blue-dot-list";
@import "styles/partner-card";

@import url('https://fonts.googleapis.com/css?family=Istok+Web|Source+Sans+Pro|Source+Code+Pro');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;1,300;1,500&display=swap');

html, body { height: 100%; }
