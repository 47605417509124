@import "../variables";

ul.blue-dot-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-left: 30px;
    margin-bottom: 25px;
    position: relative;
  }

  li::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    left: 0;
    background: $blue;
  }
}
